// Controls.tsx

import React, { memo } from 'react';
import { Play, Pause, Rewind, FastForward } from 'lucide-react';

// #region [ Interfaces / Types ]

/**
 * Describes the props required by the Controls component.
 */
export interface ControlsProps {
  /** Whether the media is currently playing or paused. */
  isPlaying: boolean;

  /** Handler function triggered when play/pause is clicked. */
  onPlayPause: () => void;

  /** Handler function triggered when rewind is clicked. */
  onRewind: () => void;

  /** Handler function triggered when fast-forward is clicked. */
  onForward: () => void;
}

/**
 * A button sub-component that standardizes styling and accessibility.
 */
interface ControlButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.ReactNode;
  label: string;
}

// #endregion

// #region [ Sub-Components ]

/**
 * A small sub-component to keep button styling consistent and centralized.
 * You could expand this with more logic (like disabling, loading states, etc.)
 * without cluttering the main Controls component.
 */
const ControlButton: React.FC<ControlButtonProps> = ({
  icon,
  label,
  ...props
}) => {
  return (
    <button
      type="button"
      className="p-1 hover:-translate-y-[1px] transition-transform"
      aria-label={label}
      {...props}
    >
      {icon}
    </button>
  );
};

// #endregion

/**
 * Controls:
 * A lightweight panel of playback buttons (rewind, play/pause, forward)
 * that trigger the provided callback methods.
 *
 * By wrapping in `memo`, re-renders caused by unrelated props at a higher
 * level of the app can be avoided, improving performance in large apps.
 */
const Controls: React.FC<ControlsProps> = memo(
  ({ isPlaying, onPlayPause, onRewind, onForward }) => {
    return (
      <div className="flex items-center space-x-2">
        <ControlButton
          onClick={onRewind}
          icon={<Rewind className="w-4 h-4 text-white" />}
          label="Rewind 10 seconds"
        />
        <ControlButton
          onClick={onPlayPause}
          icon={
            isPlaying ? (
              <Pause className="w-5 h-5 text-white" />
            ) : (
              <Play className="w-5 h-5 text-white" />
            )
          }
          label={isPlaying ? 'Pause' : 'Play'}
        />
        <ControlButton
          onClick={onForward}
          icon={<FastForward className="w-4 h-4 text-white" />}
          label="Forward 10 seconds"
        />
      </div>
    );
  }
);

Controls.displayName = 'Controls';
export default Controls;