// ProgressBar.tsx

import React, { memo, useState, useRef, useCallback } from 'react';

// #region [ Interfaces / Types ]

export interface ProgressBarProps {
  /**
   * The current playback time of the media (in seconds).
   */
  currentTime: number;

  /**
   * The total duration of the media (in seconds).
   */
  duration: number;

  /**
   * A callback that allows the user to seek to a new time.
   * The callback receives the new time (in seconds) as its argument.
   */
  onSeek: (time: number) => void;
}

// #endregion

/**
 * A simple progress bar that handles clicking and dragging (scrubbing)
 * to seek through media content. It visually displays the currentTime
 * in relation to duration.
 */
const ProgressBar: React.FC<ProgressBarProps> = memo(
  ({ currentTime, duration, onSeek }) => {
    const progressBarRef = useRef<HTMLDivElement>(null);
    const [isScrubbing, setIsScrubbing] = useState<boolean>(false);

    /**
     * Sets the component in "scrubbing" mode when the user presses
     * the mouse button down on the progress bar.
     */
    const handleMouseDown = useCallback(() => {
      setIsScrubbing(true);
    }, []);

    /**
     * Releases "scrubbing" mode when the user lifts the mouse button.
     */
    const handleMouseUp = useCallback(() => {
      setIsScrubbing(false);
    }, []);

    /**
     * Handles mouse movement over the progress bar.
     * If the user is scrubbing, we calculate a new time based on mouse position
     * and pass it to the `onSeek` callback.
     */
    const handleMouseMove = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (progressBarRef.current && isScrubbing) {
          const rect = progressBarRef.current.getBoundingClientRect();
          const position = (event.clientX - rect.left) / rect.width;
          onSeek(position * duration);
        }
      },
      [duration, isScrubbing, onSeek]
    );

    /**
     * Handles a click on the progress bar (without dragging).
     * Seeks to the new time based on the click position.
     */
    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (progressBarRef.current) {
          const rect = progressBarRef.current.getBoundingClientRect();
          const clickPosition = (event.clientX - rect.left) / rect.width;
          onSeek(clickPosition * duration);
        }
      },
      [duration, onSeek]
    );

    // Calculate the width of the progress bar in percentage terms,
    // ensuring it never exceeds 100%.
    const progressWidth = `${Math.min(
      (currentTime / duration) * 100,
      100
    )}%`;

    return (
      <div
        ref={progressBarRef}
        className="relative w-full h-1 bg-gray-600 cursor-pointer"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        aria-label="Seek bar"
        role="progressbar"
        aria-valuenow={currentTime}
        aria-valuemin={0}
        aria-valuemax={duration}
      >
        {/* The filled portion of the progress bar */}
        <div
          className="absolute top-0 left-0 h-full bg-white"
          style={{ width: progressWidth }}
        />
        {/* A small handle or indicator at the current position */}
        <div
          className="absolute top-0 h-full w-1 bg-white"
          style={{ left: progressWidth }}
        />
      </div>
    );
  }
);

ProgressBar.displayName = 'ProgressBar';
export default ProgressBar;