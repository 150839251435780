"use client";

import React, { useState, useEffect } from "react";
import { useAuth } from "@clerk/nextjs";
import Image from "next/image";
import Link from "next/link";
import { UserButton } from "@clerk/nextjs";
import { Button } from "@/components/ui/button";
import MobileNavigationBar from "./MobileNavigationBar";
import { useRouter } from "next/navigation";
import { Montserrat } from "next/font/google";

// 1. Load Montserrat from next/font/google.
const montserrat = Montserrat({
  subsets: ["latin"],
  weight: ["400", "500", "700"], // adjust as needed
});

interface NavigationBarProps {
  isDevComplete: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isDevComplete }) => {
  const { isSignedIn } = useAuth();
  const [isMobile, setIsMobile] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // If on a small screen, return the mobile navigation.
  if (isMobile) {
    return <MobileNavigationBar />;
  }

  return (
    <nav
      className={`
        ${montserrat.className} 
        flex items-center p-6 
        bg-white/70 backdrop-blur-sm 
        z-50 sticky top-0
      `}
    >
      {/* Logo / Home link */}
      <div className="flex-1">
        <Link
          href="/"
          className="transition-transform hover:scale-105 duration-300 block"
        >
          <Image
            src="/logo.png"
            alt="Melo Logo"
            width={120}
            height={40}
            priority
            className="drop-shadow-lg"
          />
        </Link>
      </div>

      {/* Right side navigation (Auth / CTA) */}
      <div className="flex-1 flex justify-end items-center space-x-6">
        {isSignedIn ? (
          <>
            <Link href="/conversation">
              <Button
                variant="outline"
                className="
                  rounded-full text-gray-700 bg-white 
                  border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-black 
                  px-8 py-2.5 text-base transition-all duration-300 
                  shadow-sm
                "
              >
                <span className="relative">dashboard</span>
              </Button>
            </Link>
            <div className="transform hover:scale-105 transition-transform duration-300">
              <UserButton
                afterSignOutUrl="/"
                appearance={{
                  elements: {
                    avatarBox: "bg-gray-50 rounded-lg",
                  },
                }}
              />
            </div>
          </>
        ) : (
          <>
            {isDevComplete ? (
              <>
                <Link href="/sign-up">
                  <Button
                    variant="outline"
                    className="
                      rounded-full text-gray-700 bg-white 
                      border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-black 
                      px-8 py-2.5 text-base transition-all duration-300 
                      shadow-sm
                    "
                  >
                    <span className="relative">sign up</span>
                  </Button>
                </Link>
                <Link href="/sign-in">
                  <Button
                    variant="outline"
                    className="
                      rounded-full text-gray-700 bg-white 
                      border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-black 
                      px-8 py-2.5 text-base transition-all duration-300 
                      shadow-sm
                    "
                  >
                    <span className="relative">sign in</span>
                  </Button>
                </Link>
              </>
            ) : (
              <Button
                disabled
                className="
                  bg-gray-50 text-gray-500 
                  border border-gray-200 
                  rounded-full px-8 py-2.5 text-base 
                  shadow-sm
                "
              >
                <span className="relative">Coming Soon :)</span>
              </Button>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;