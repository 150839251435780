"use client";

import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAuth } from "@clerk/nextjs";
import { UserButton } from "@clerk/nextjs";
import { Button } from "@/components/ui/button";
import { Menu, X } from "lucide-react";

export default function MobileNavigationBar() {
  const { isSignedIn } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const isDevComplete = true; // or false for demonstration

  const toggleMenu = () => setIsOpen((prev) => !prev);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white h-16 sm:h-20">
      {/* Top bar */}
      <div className="flex justify-between items-center p-4">
        <Link href="/" legacyBehavior>
          <button className="focus:outline-none">
            <Image
              src="/logo.png"
              alt="Melo Logo"
              width={100}
              height={40}
              priority
            />
          </button>
        </Link>
        
        {/* Hamburger / Close button (white background, black lines) */}
        <button
          onClick={toggleMenu}
          className="flex items-center justify-center bg-white border border-black text-black w-10 h-10 rounded-md 
                     hover:bg-black hover:text-white transition-colors duration-200"
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Mobile menu overlay */}
      {isOpen && (
        <div className="fixed inset-0 z-50 backdrop-blur-lg bg-black/30">
          {/* Sidebar container */}
          <div className="relative flex flex-col h-full w-full p-6 space-y-6 bg-white text-black">
            {/* Close / Logo row */}
            <div className="flex justify-between items-center">
              <Link href="/" passHref legacyBehavior>
                <button className="focus:outline-none">
                  <Image
                    src="/logo.png"
                    alt="Melo Logo"
                    width={100}
                    height={40}
                    priority
                  />
                </button>
              </Link>
              <button
                onClick={toggleMenu}
                className="flex items-center justify-center bg-white border border-black text-black w-10 h-10 rounded-md
                           hover:bg-black hover:text-white transition-colors duration-200"
              >
                <X size={24} />
              </button>
            </div>

            {/* Signed in vs. dev complete vs. coming soon */}
            {isSignedIn ? (
              <div className="space-y-4 flex flex-col items-center w-full">
                <div className="relative group">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-pink-600 to-purple-600 rounded-full opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt blur"></div>
                  <UserButton
                    afterSignOutUrl="/"
                    appearance={{
                      elements: {
                        avatarBox:
                          "h-20 w-20 rounded-full ring-2 ring-black hover:ring-4 transition-all duration-300 ease-in-out",
                        userButtonPopoverCard:
                          "bg-white border border-gray-300 text-black",
                        userButtonPopoverActions: "text-black",
                        userButtonPopoverActionButton:
                          "hover:bg-gray-200 transition-colors"
                      }
                    }}
                  />
                </div>
                <Link href="/conversation" className="w-full mt-4">
                  <Button className="w-full bg-transparent text-black border border-black hover:bg-black hover:text-white transition-colors duration-300 rounded-full">
                    Dashboard
                  </Button>
                </Link>
              </div>
            ) : isDevComplete ? (
              <div className="space-y-10">
                <Link href="/sign-in" className="block mb-6">
                  <Button className="w-full bg-transparent text-black border border-black hover:bg-black hover:text-white transition-colors duration-300 rounded-full">
                    Sign In
                  </Button>
                </Link>
                <Link href="/sign-up" className="block">
                  <Button className="w-full bg-transparent text-black border border-black hover:bg-black hover:text-white transition-colors duration-300 rounded-full">
                    Sign Up
                  </Button>
                </Link>
              </div>
            ) : (
              <div className="space-y-4">
                <Button
                  disabled
                  className="w-full bg-gray-200 text-black border border-black opacity-70 cursor-not-allowed rounded-full 
                             hover:bg-gray-300 transition-colors duration-300"
                >
                  Coming Soon :)
                </Button>
              </div>
            )}

            {/* Pricing link if dev is complete */}
            {isDevComplete && (
              <Link
                href="/pages/pricing"
                className="text-xl font-semibold hover:text-gray-700"
              >
                Pricing
              </Link>
            )}

            {/* Product links */}
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Product</h3>
              {isDevComplete ? (
                <>
                  <Link
                    href="/pages/ios-waitlist"
                    className="block hover:text-gray-700"
                  >
                    iOS Waitlist
                  </Link>
                  <Link
                    href="/pages/drop-podcast"
                    className="block hover:text-gray-700"
                  >
                    Drop Podcast
                  </Link>
                  <Link
                    href="/pages/back-melo"
                    className="block hover:text-gray-700"
                  >
                    Back Melo
                  </Link>
                  <Link
                    href="/pages/melo-philosophy"
                    className="block hover:text-gray-700"
                  >
                    melo philosophy
                  </Link>
                </>
              ) : (
                <Link
                  href="/pages/coming-soon"
                  className="block hover:text-gray-700"
                >
                  Coming Soon
                </Link>
              )}
            </div>

            {/* Company links */}
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Company</h3>
              <Link
                href="/pages/about"
                className="block hover:text-gray-700"
              >
                About
              </Link>
              <Link
                href="/pages/contact"
                className="block hover:text-gray-700"
              >
                Contact
              </Link>
              <Link
                href="/pages/privacy-policy"
                className="block hover:text-gray-700"
              >
                Privacy Policy
              </Link>
              <Link
                href="/pages/terms-of-service"
                className="block hover:text-gray-700"
              >
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}