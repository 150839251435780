// VideoPlayer.tsx

import React from 'react';
import VideoPlayerManager from './VideoPlayerManager';

// #region [ Interfaces / Types ]

export interface VideoPlayerProps {
  url: string;
  start: number;
  end: number;
  podcastName: string;
  episodeName: string;
  clipDuration: number;
  onToggleFullEpisode: () => void;
  isFullEpisode?: boolean;
}

// #endregion

/**
 * A higher-level component that wraps the VideoPlayerManager.
 * It could include additional layout or container logic.
 */
const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  start,
  end,
  podcastName,
  episodeName,
  clipDuration,
  onToggleFullEpisode,
  isFullEpisode = false,
}) => {
  return (
    <div className="video-player-container rounded-lg overflow-hidden relative">
      <VideoPlayerManager
        url={url}
        start={start}
        end={end}
        isFullEpisode={isFullEpisode}
        onToggleFullEpisode={onToggleFullEpisode}
        clipDuration={clipDuration}
        podcastName={podcastName}
        episodeName={episodeName}
      />
    </div>
  );
};

export default VideoPlayer;