// HotkeysHandler.tsx

import React, { memo, useEffect, useCallback } from 'react';

// #region [ Interfaces / Types ]

export interface HotkeysHandlerProps {
  /** Toggles play/pause of the media. */
  onPlayPause: () => void;

  /** Rewinds the media (e.g., 10 seconds). */
  onRewind: () => void;

  /** Fast-forwards the media (e.g., 10 seconds). */
  onForward: () => void;

  /** Increases volume (e.g., +10%). */
  onVolumeUp: () => void;

  /** Decreases volume (e.g., -10%). */
  onVolumeDown: () => void;

  /** Toggles fullscreen mode. */
  onToggleFullscreen: () => void;

  /** A ref to the container that should listen for keyboard events. */
  containerRef: React.RefObject<HTMLDivElement>;
}

// #endregion

/**
 * A component that attaches keyboard event listeners to the provided `containerRef`.
 * It listens for various hotkeys to control media playback, seeking, volume, etc.
 *
 * Because it returns `null`, it doesn't render anything in the DOM;
 * it simply manages event listeners and triggers callbacks.
 */
const HotkeysHandler: React.FC<HotkeysHandlerProps> = memo(
  ({
    onPlayPause,
    onRewind,
    onForward,
    onVolumeUp,
    onVolumeDown,
    onToggleFullscreen,
    containerRef,
  }) => {
    /**
     * Keypress handler that checks for relevant keyboard shortcuts.
     * We also ignore key presses if focus is on an input or textarea.
     */
    const handleKeyPress = useCallback(
      (event: KeyboardEvent) => {
        // Ignore key presses if focus is on an input or textarea
        if (
          event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement
        ) {
          return;
        }

        switch (event.key.toLowerCase()) {
          case ' ':
          case 'k':
            event.preventDefault();
            onPlayPause();
            break;
          case 'arrowleft':
          case 'j':
            onRewind();
            break;
          case 'arrowright':
          case 'l':
            onForward();
            break;
          case 'arrowup':
            event.preventDefault();
            onVolumeUp();
            break;
          case 'arrowdown':
            event.preventDefault();
            onVolumeDown();
            break;
          case 'f':
            onToggleFullscreen();
            break;
          case 'm':
            event.preventDefault();
            // Add mute functionality if needed
            break;
          default:
            break;
        }
      },
      [
        onPlayPause,
        onRewind,
        onForward,
        onVolumeUp,
        onVolumeDown,
        onToggleFullscreen,
      ]
    );

    /**
     * Attach the 'keydown' listener to the container when it mounts
     * and clean up when it unmounts or dependencies change.
     */
    useEffect(() => {
      const currentContainer = containerRef.current;
      if (currentContainer) {
        currentContainer.addEventListener('keydown', handleKeyPress);
      }

      return () => {
        if (currentContainer) {
          currentContainer.removeEventListener('keydown', handleKeyPress);
        }
      };
    }, [handleKeyPress, containerRef]);

    return null;
  }
);

HotkeysHandler.displayName = 'HotkeysHandler';
export default HotkeysHandler;