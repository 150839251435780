// TimeDisplay.tsx

import React, { memo } from 'react';

// #region [ Interfaces / Types ]

/**
 * The props for the TimeDisplay component.
 */
export interface TimeDisplayProps {
  /**
   * The current time (in seconds) that the media has been playing.
   */
  currentTime: number;

  /**
   * The total duration (in seconds) of the media.
   */
  duration: number;
}

// #endregion

/**
 * A helper function that formats a time value (in seconds) into a
 * "hh:mm:ss" or "mm:ss" string, depending on whether hours are present.
 */
const formatTime = (timeInSeconds: number): string => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

/**
 * Displays the current playback time and total duration in a
 * [mm:ss] or [hh:mm:ss] format, depending on whether hours are present.
 */
const TimeDisplay: React.FC<TimeDisplayProps> = memo(
  ({ currentTime, duration }) => {
    return (
      <div
        className="text-sm text-white font-medium font-mono"
        role="timer"
        aria-label="Time display"
      >
        <span>{formatTime(currentTime)}</span>
        <span className="mx-1">/</span>
        <span>{formatTime(duration)}</span>
      </div>
    );
  }
);

TimeDisplay.displayName = 'TimeDisplay';
export default TimeDisplay;