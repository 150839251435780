import React from 'react';

interface SuggestedQuestionsProps {
  onQuestionClick: (question: string) => void;
}

const SuggestedQuestions: React.FC<SuggestedQuestionsProps> = ({ onQuestionClick }) => {
  const questions = [
    "Where should I travel in 2024?",
    "What's a good way to make new friends?",
    "How can I boost my confidence?",
    "How can I build genuine connections?"
  ];

  return (
    <div className="w-full">
      <div className="text-sm text-gray-900 mb-2">TRY ASKING</div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {questions.map((question, index) => (
          <button
            key={index}
            onClick={() => onQuestionClick(question)}
            className="
              text-left px-4 py-3 
              bg-white text-gray-900
              border-2 border-gray-200
              rounded-full text-sm
              transition-all duration-500 ease-in-out
              hover:text-gray-900
              hover:border-black
              hover:-translate-y-2
              hover:text-base
              shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
              hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]
            "
          >
            {question}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SuggestedQuestions;

