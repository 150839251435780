'use client';

import React, { useState, useEffect } from "react";
import { Quicksand } from 'next/font/google';
import { useSession, SessionProvider } from "next-auth/react";
import LandingHero from "@/components/landing-hero";
import NavigationBar from "@/components/NavigationBar";
import Image from 'next/image';
import '@/app/globals.css';
import ConversationPreview from './components/ConversationPreview';
import Footer from './components/Footer';
import TopicsFeatureCard from './components/TopicsFeatureCard';
import ProfileSetupCard from './components/ProfileSetupCard';
import { useRouter } from 'next/navigation';
import { useAuth } from '@clerk/clerk-react';

const quicksand = Quicksand({ subsets: ['latin'], weight: ['300'] });

const LandingPageContent: React.FC = () => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [isProfileSetupVisible, setIsProfileSetupVisible] = useState(true);
  const { isSignedIn, isLoaded } = useAuth();

  const isDevComplete = true; // Set this to true when development is complete

  useEffect(() => {
    setMounted(true);
    // Simulate a short loading time
    setTimeout(() => setIsLoading(false), 1000);

    // Redirect to dashboard if user is authenticated
    if (status === "authenticated") {
      router.push('/dashboard');
    }
  }, [status, router]);

  if (!mounted) {
    return null; // or a simple loading placeholder
  }

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
          <Image
            src="/logo.png"
            alt="Logo"
            style={{ objectFit: 'cover' }}
            width={350}
            height={350}
            className="animate-pulse"
          />
        </div>
      )}
      <div className={`${quicksand.className} min-h-screen overflow-x-hidden bg-white text-black ${isLoading ? 'invisible' : 'fade-in'}`}>
        <div className="relative min-h-screen flex flex-col">
          <NavigationBar isDevComplete={isDevComplete} />
          <main className="flex-grow flex flex-col items-center justify-start px-4 sm:px-4 py-8 sm:py-14">
            <div className="w-full max-w-6xl space-y-12 sm:space-y-22">
              <LandingHero isDevComplete={isDevComplete} />
              <div className="h-4"></div>
              <ConversationPreview isSignedIn={isSignedIn ?? false} />
              
              <div className="h-24"></div>

              <ProfileSetupCard isVisible={isProfileSetupVisible} />

              <div className="h-24"></div>

              <TopicsFeatureCard />

              <div className="h-24"></div>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

const LandingPage: React.FC = () => {
  return (
    <SessionProvider>
      <LandingPageContent />
    </SessionProvider>
  );
};

export default LandingPage;
