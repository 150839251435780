"use client";
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { toast } from 'react-hot-toast';
import { FaPlay, FaStop } from 'react-icons/fa';
import { useRouter } from 'next/navigation';
import { CgSpinner } from 'react-icons/cg';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";

import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

import VideoPlayer from '@/app/(dashboard)/(routes)/conversation/components/videoplayer/VideoPlayer';
import SuggestedQuestions from './SuggestedQuestions';

import { formatTime } from '@/lib/utils';
import { SearchProgressStep } from '@/app/api/conversation/types';

// -----------------------------
// SCHEMA, INTERFACES & CONSTANTS
// -----------------------------

/**
 * Zod schema for validating our form input.
 */
const formSchema = z.object({
  prompt: z.string().min(1, {
    message: "ask melo something ;)"
  })
});

interface ConversationPreviewProps {
  isSignedIn: boolean;
}

/**
 * Represents the typical structure of a chat message.
 */
interface Message {
  role: 'user' | 'assistant';
  content: string;
}

// -----------------------------
// MAIN COMPONENT
// -----------------------------
const ConversationPreview: React.FC<ConversationPreviewProps> = ({ isSignedIn }) => {
  // -----------------------------
  // 1. STATE HOOKS
  // -----------------------------
  const [messages, setMessages] = useState<Message[]>([]);
  const [videoInfo, setVideoInfo] = useState<any | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [generationCount, setGenerationCount] = useState(0);
  const [triesCount, setTriesCount] = useState(0);
  const [usageCount, setUsageCount] = useState(0);
  const [progress, setProgress] = useState<SearchProgressStep[]>([]);
  const [showLimitReachedPopup, setShowLimitReachedPopup] = useState(false);

  const router = useRouter();

  // Loading states for sign-up and sign-in buttons
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isSignInLoading, setIsSignInLoading] = useState(false);

  // For request cancellation
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  // -----------------------------
  // 2. EFFECT HOOKS
  // -----------------------------
  // Retrieve usage data from localStorage (simple client-side approach)
  useEffect(() => {
    const savedTriesCount = localStorage.getItem('previewTriesCount');
    if (savedTriesCount) {
      setTriesCount(parseInt(savedTriesCount, 10));
    }

    const savedUsageCount = localStorage.getItem('previewUsageCount');
    if (savedUsageCount) {
      setUsageCount(parseInt(savedUsageCount, 10));
    }
  }, []);

  // -----------------------------
  // 3. FORM SETUP
  // -----------------------------
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      prompt: ""
    }
  });

  // -----------------------------
  // 4. HELPER FUNCTIONS
  // -----------------------------

  /**
   * Helper to decide the API endpoint based on sign-in status.
   * Values come from environment variables with fallbacks.
   */
  const getApiEndpoint = (): string => {
    const conversationApi = process.env.NEXT_PUBLIC_CONVERSATION_ENDPOINT || '/api/conversation';
    const conversationPreviewApi = process.env.NEXT_PUBLIC_CONVERSATION_PREVIEW_ENDPOINT || '/api/conversation-preview';

    return isSignedIn ? conversationApi : conversationPreviewApi;
  };

  /**
   * Helper to handle the actual conversation fetch logic.
   * Uses streaming (Fetch API with a ReadableStream).
   */
  const fetchConversation = async (
    prompt: string,
    existingMessages: Message[],
    usageCountValue: number,
    controller: AbortController
  ): Promise<void> => {
    const endpoint = getApiEndpoint();

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        messages: [...existingMessages, { role: 'user', content: prompt }],
        usageCount: usageCountValue
      }),
      signal: controller.signal
    });

    // Non-OK response handling
    if (!response.ok) {
      const errorData = await response.json();
      // If user hits limit
      if (response.status === 403 && errorData.type === 'error') {
        setShowLimitReachedPopup(true);
        throw new Error(errorData.message);
      }
      throw new Error("Failed to get a response");
    }

    // Get the response body as a reader for SSE streaming
    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error("Failed to get response reader");
    }

    // Read the stream
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      // Decode
      const decodedValue = new TextDecoder().decode(value);
      const events = decodedValue.split('\n\n').filter(Boolean);

      // Process each event chunk
      for (const event of events) {
        if (event.startsWith('data: ')) {
          const data = JSON.parse(event.slice(6));

          if (data.type === 'progress') {
            // Update progress steps
            setProgress(data.data);
          } else if (data.type === 'result') {
            // Store the new AI message
            setMessages(prev => [
              ...prev,
              { role: 'user', content: prompt },
              data.data.message
            ]);

            // If there's relevant video info
            if (data.data.videoInfo) {
              setVideoInfo(data.data.videoInfo);
            }

            // Handle usage counts:
            if (!isSignedIn) {
              setUsageCount(prevCount => {
                const newCount = prevCount + 1;
                localStorage.setItem('previewUsageCount', newCount.toString());
                return newCount;
              });
            } else if (data.data.updatedGenerationCount !== undefined) {
              setGenerationCount(data.data.updatedGenerationCount);
            }
          } else if (data.type === 'error') {
            toast.error(data.message);
          }
        }
      }
    }
  };

  /**
   * Handles submission of the form (user question).  
   * Manages loading state and the abort controller.
   */
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (isLoading) {
      // If user triggers while loading, cancel existing request
      abortController?.abort();
      setIsLoading(false);
      setProgress([]);
      return;
    }

    if (!values.prompt.trim()) {
      toast.error("Please enter a question");
      return;
    }

    const controller = new AbortController();
    setAbortController(controller);
    setIsLoading(true);
    setVideoInfo(null);
    setProgress([]);

    try {
      // Actually fetch data from your conversation API
      await fetchConversation(values.prompt, messages, usageCount, controller);
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        toast.success('Request cancelled');
      } else {
        console.error('Error:', error);
        toast.error(error instanceof Error ? error.message : "An error occurred. Please try again.");
      }
    } finally {
      // Cleanup
      setIsLoading(false);
      setProgress([]);
      setAbortController(null);
      form.reset();
    }
  };

  /**
   * Suggested question click handler.
   * Automatically sets the question and triggers form submission.
   */
  const handleQuestionClick = (question: string) => {
    form.setValue('prompt', question);
    form.handleSubmit(onSubmit)();
  };

  // Sign Up or Sign In event handlers
  const handleSignUp = async () => {
    setIsSignUpLoading(true);
    try {
      await router.push('/sign-up');
    } finally {
      setIsSignUpLoading(false);
    }
  };

  const handleLogIn = async () => {
    setIsSignInLoading(true);
    try {
      await router.push('/sign-in');
    } finally {
      setIsSignInLoading(false);
    }
  };

  // General button click for usage limit or going to topics
  const handleButtonClick = () => {
    if (isSignedIn) {
      router.push('/topics');
    } else {
      setShowLimitReachedPopup(true);
    }
  };

  // -----------------------------
  // 5. RENDER: LOADING SPINNER, PROGRESS, MESSAGES, ETC.
  // -----------------------------
  const LoadingSpinner: React.FC = () => (
    <div className="flex justify-center items-center mt-4">
      <CgSpinner className="animate-spin text-black text-4xl" />
    </div>
  );

  const ProgressIndicator: React.FC<{ progress: SearchProgressStep[] }> = ({ progress }) => (
    <div className="mt-4 space-y-2">
      {progress.map((step, index) => (
        <div key={index} className="flex items-center">
          <div
            className={`w-4 h-4 rounded-full mr-2 ${
              step.completed
                ? 'bg-green-500'
                : step.loading
                ? 'bg-black animate-pulse'
                : 'bg-gray-300'
            }`}
          ></div>
          <span
            className={`${
              step.completed
                ? 'text-green-500'
                : step.loading
                ? 'text-black'
                : 'text-gray-500'
            }`}
          >
            {step.step}
          </span>
        </div>
      ))}
    </div>
  );

  // -----------------------------
  // 6. RETURN JSX
  // -----------------------------
  return (
    <div className="w-full max-w-3xl mx-auto space-y-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="relative max-w-4xl mx-auto">
          <FormField
            name="prompt"
            render={({ field }) => (
              <FormItem className="relative">
                <FormControl>
                  <div className="relative group">
                    {/* Outer Container */}
                    <div
                      className="
                        relative bg-white rounded-full border-2 border-gray-200 hover:border-gray-300
                        transition-all duration-500 ease-in-out
                        shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                        hover:-translate-y-2 hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]
                      "
                    >
                      <Input
                        disabled={isLoading}
                        placeholder={isLoading ? "Processing your question..." : "ask melo something..."}
                        {...field}
                        className="
                          w-full bg-transparent
                          text-[16px] tracking-wider
                          text-gray-900 placeholder:text-gray-900
                          py-8 pl-6 pr-16
                          border-0 rounded-full
                          ring-0 focus:ring-0
                          focus:outline-none
                          transition-all duration-500 ease-in-out
                          group-hover:placeholder:text-gray-500
                          group-hover:text-[18px]
                        "
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            form.handleSubmit(onSubmit)();
                          }
                        }}
                      />
                      {/* Submit / Cancel Button */}
                      <Button
                        type="submit"
                        disabled={false}
                        className="
                          absolute right-4 top-1/2 transform -translate-y-1/2
                          bg-gray-50 hover:bg-gray-100
                          text-gray-800
                          p-4 rounded-full
                          transition-all duration-500 ease-in-out
                          focus:outline-none
                          hover:translate-y-[-60%]
                          hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]
                          shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                          border border-gray-200 hover:border-black
                        "
                      >
                        {isLoading ? (
                          <FaStop className="w-4 h-4 text-red-500" />
                        ) : (
                          <FaPlay className="w-4 h-4" />
                        )}
                      </Button>
                    </div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>

      {/* Suggested Questions */}
      <SuggestedQuestions onQuestionClick={handleQuestionClick} />

      {/* Loading State */}
      {isLoading && <LoadingSpinner />}
      {progress.length > 0 && <ProgressIndicator progress={progress} />}

      {/* Render Chat Messages */}
      {messages.length > 0 && (
        <div className="space-y-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`p-4 rounded-lg ${
                message.role === 'user'
                  ? 'bg-white border-2 border-gray-200 text-gray-900 shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]'
                  : 'bg-gray-50 border-2 border-gray-200 text-gray-800 shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]'
              }`}
            >
              {message.role === 'user' ? (
                message.content
              ) : (
                <pre className="font-mono text-gray-900 text-xs overflow-x-auto whitespace-pre-wrap break-words">
                  {`Podcast: ${videoInfo?.podcastName || 'N/A'}
Episode: ${videoInfo?.episodeName || 'N/A'}
Timestamp: ${videoInfo?.start ? formatTime(videoInfo.start) : 'N/A'} - ${videoInfo?.end ? formatTime(videoInfo.end) : 'N/A'}`}
                </pre>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Video Player */}
      {videoInfo && (
        <div className="mt-6">
          <VideoPlayer
            url={videoInfo.url}
            start={videoInfo.start}
            end={videoInfo.end}
            podcastName={videoInfo.podcastName}
            episodeName={videoInfo.episodeName}
            clipDuration={videoInfo.end - videoInfo.start}
            onToggleFullEpisode={() => {}}
          />
        </div>
      )}

      {/* Generation Count for Signed-in Users */}
      {generationCount > 0 && (
        <div className="text-center text-gray-800">
          <p>Generation count: {generationCount}</p>
        </div>
      )}

      {/* Usage Count for Anonymous Users */}
      {usageCount > 0 && (
        <div
          className="
            text-center text-sm text-gray-800 bg-white
            border-2 border-gray-200 py-2 rounded-full
            shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
          "
        >
          {usageCount === 1 ? "1 free use" : `${usageCount} free uses`} used.
          {2 - usageCount} {2 - usageCount === 1 ? "use" : "uses"} remaining.
          <Button
            onClick={handleButtonClick}
            className="
              ml-4 bg-black text-white 
              hover:bg-gray-800 
              transition-all duration-200 
              rounded-full px-4 py-1 text-sm
              hover:translate-x-[2px] hover:translate-y-[2px]
              shadow-[2px_2px_0px_0px_rgba(0,0,0,0.1)]
            "
          >
            {isSignedIn ? "Go to Topics" : "Sign Up"}
          </Button>
        </div>
      )}

      {/* Limit Reached Popup (Dialog) */}
      <Dialog open={showLimitReachedPopup} onOpenChange={setShowLimitReachedPopup}>
        <DialogContent
          className="
            sm:max-w-[425px] bg-white backdrop-blur-xl
            text-black border border-gray-200
            rounded-none shadow-xl
          "
        >
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold">Free Usage Limit Reached</DialogTitle>
            <DialogDescription className="text-gray-800 mt-2">
              You&apos;ve reached the maximum number of free uses. Sign up now to unlock unlimited access!
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end space-x-4 mt-8">
            <Button
              variant="outline"
              onClick={handleLogIn}
              disabled={isSignInLoading}
              className={cn(
                "bg-transparent hover:bg-gray-50 text-gray-800 border-gray-200",
                "transition-all duration-200 ease-in-out rounded-none px-6 py-2",
                isSignInLoading ? "opacity-50" : "hover:scale-105"
              )}
            >
              {isSignInLoading ? "Signing In..." : "Sign In"}
            </Button>
            <Button
              onClick={handleSignUp}
              disabled={isSignUpLoading}
              className={cn(
                "bg-black text-white hover:bg-gray-800 hover:text-white",
                "transition-all duration-200 ease-in-out rounded-none px-6 py-2",
                isSignUpLoading ? "opacity-50" : "hover:scale-105"
              )}
            >
              {isSignUpLoading ? "Signing Up..." : "Sign Up"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Additional Global Styles for Animations */}
      <style jsx global>{`
        @keyframes moveRight {
          from { transform: translateX(-100%); }
          to { transform: translateX(200%); }
        }
        @keyframes moveLeft {
          from { transform: translateX(200%); }
          to { transform: translateX(-100%); }
        }
        .animate-pulse-slow {
          animation: pulse 3s ease-pulse-ease infinite;
        }
      `}</style>
    </div>
  );
};

export default ConversationPreview;
