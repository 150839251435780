// VolumeControl.tsx

import React, { useState, useRef, useEffect, useCallback } from 'react';
import Image from 'next/image';

// #region [ Interfaces / Types ]

export interface VolumeControlProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
}

// #endregion

/**
 * A volume control that shows a small slider on hover
 * and toggles mute/unmute when the icon is clicked.
 */
const VolumeControl: React.FC<VolumeControlProps> = ({
  volume,
  onVolumeChange,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const hideTimerRef = useRef<number | null>(null);
  const showTimerRef = useRef<number | null>(null);

  const startHideTimer = useCallback(() => {
    if (hideTimerRef.current !== null) {
      window.clearTimeout(hideTimerRef.current);
    }
    hideTimerRef.current = window.setTimeout(() => {
      setIsVisible(false);
    }, 2000);
  }, []);

  const handleVolumePosition = useCallback(
    (clientX: number) => {
      if (sliderRef.current) {
        const rect = sliderRef.current.getBoundingClientRect();
        const newVolume = (clientX - rect.left) / rect.width;
        onVolumeChange(Math.max(0, Math.min(1, newVolume)));
      }
    },
    [onVolumeChange]
  );

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      if (isDragging) {
        handleVolumePosition(e.clientX);
      }
    },
    [isDragging, handleVolumePosition]
  );

  const handlePointerUp = useCallback(() => {
    setIsDragging(false);
    document.removeEventListener('pointermove', handlePointerMove);
    document.removeEventListener('pointerup', handlePointerUp);
    startHideTimer();
  }, [handlePointerMove, startHideTimer]);

  const handlePointerDown = (e: React.PointerEvent) => {
    setIsDragging(true);
    handleVolumePosition(e.clientX);

    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerup', handlePointerUp);
    e.preventDefault();
  };

  const handleMouseEnter = () => {
    if (showTimerRef.current !== null) {
      clearTimeout(showTimerRef.current);
    }
    showTimerRef.current = window.setTimeout(() => {
      setIsVisible(true);
    }, 1000);
  };

  const handleMouseLeave = () => {
    if (showTimerRef.current !== null) {
      clearTimeout(showTimerRef.current);
    }
    if (!isDragging) {
      startHideTimer();
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);

      if (hideTimerRef.current !== null) {
        clearTimeout(hideTimerRef.current);
      }
      if (showTimerRef.current !== null) {
        clearTimeout(showTimerRef.current);
      }
    };
  }, [handlePointerMove, handlePointerUp]);

  const toggleMute = () => {
    // If volume is currently > 0, mute; otherwise unmute (restore to 1)
    if (volume === 0) {
      onVolumeChange(1);
    } else {
      onVolumeChange(0);
    }
    setIsVisible(true);
    startHideTimer();
  };

  const getVolumeIcon = () => {
    return volume === 0 ? (
      <Image src="/Mute On.png" alt="Mute On" width={24} height={24} />
    ) : (
      <Image src="/Mute Off.png" alt="Mute Off" width={24} height={24} />
    );
  };

  return (
    <div
      className="relative flex items-center group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        className="p-1 hover:bg-white/10 transition-colors duration-200"
        onClick={toggleMute}
        aria-label={volume === 0 ? 'Unmute' : 'Mute'}
      >
        {getVolumeIcon()}
      </button>

      <div
        className={`
          absolute left-full top-1/2 -translate-y-1/2 ml-1
          transition-all duration-500 ease-in-out
          ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}
        `}
      >
        <div
          ref={sliderRef}
          className="w-24 h-2 bg-white/20 cursor-pointer relative"
          onPointerDown={handlePointerDown}
        >
          <div
            className="absolute top-0 bottom-0 left-0 bg-white transition-all duration-200"
            style={{ width: `${volume * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default VolumeControl;