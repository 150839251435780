import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2, Play } from 'lucide-react';
import { FaPlay } from 'react-icons/fa';
import { useRouter } from 'next/navigation';
import { cn, formatDuration } from "@/lib/utils";
import axios from 'axios';
import { useAuth } from '@clerk/nextjs'; // Import useAuth hook from Clerk

interface Topic {
  name: string;
  count: number;
  bgColor: string;
  videoUrl?: string;
  duration?: number;
  summary?: string;
}

const TopicsFeatureCard: React.FC = () => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const router = useRouter();
  const { isSignedIn } = useAuth(); // Use the useAuth hook to check if the user is signed in

  useEffect(() => {
    // Simulating API call to fetch topics
    setTimeout(() => {
      setTopics([
        { name: 'Founder Life', count: 120, bgColor: 'bg-zinc-800/30' },
        { name: 'Life Tips', count: 200, bgColor: 'bg-zinc-800/30' },
        { name: 'Glow Up', count: 80, bgColor: 'bg-zinc-800/30' },
        { name: 'City Life', count: 100, bgColor: 'bg-zinc-800/30' },
        { name: 'Fitness Journey', count: 150, bgColor: 'bg-zinc-800/30' },
        { name: 'Culinary Adventures', count: 180, bgColor: 'bg-zinc-800/30' },
      ]);
      setIsLoading(false);
    }, 1500);
  }, []);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      const response = await axios.get(`/api/topics?query=${encodeURIComponent(searchQuery)}`);
      // Handle the search results here
      console.log('Search results:', response.data);
      // You might want to navigate to a search results page or update the UI
    } catch (error) {
      console.error('Error searching topics:', error);
    }
  };

  const handleTopicClick = (topicName: string) => {
    if (isSignedIn) {
      router.push('/topics');
    } else {
      router.push('/sign-up');
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left; // Get mouse x position within card
    const y = e.clientY - rect.top;  // Get mouse y position within card
    
    card.style.setProperty('--mouse-x', `${x}px`);
    card.style.setProperty('--mouse-y', `${y}px`);
  };

  return (
    <section className="bg-white text-black py-32 px-8">
      <div className="max-w-[1400px] mx-auto flex flex-col lg:flex-row gap-24">
        {/* Left column */}
        <div className="lg:w-[38.2%] space-y-12 flex flex-col justify-center">
          <div className="space-y-8">
            <h3 className="text-xs font-medium tracking-[0.2em] text-black uppercase">
              What MELO Offers
            </h3>
            <h2 className="text-4xl lg:text-5xl font-[350] leading-tight tracking-[-0.02em] text-black">
              Discover Topics
            </h2>
            <p className="text-black/60 text-lg leading-relaxed font-light max-w-xl">
              Dive into an array of topics tailored to your interests.
            </p>
          </div>
          
          <div className="flex justify-center">
            <button
              onClick={() => router.push('/topics')}
              className="
                bg-black 
                text-white 
                px-6 
                py-3 
                rounded-lg 
                border 
                border-black 
                hover:bg-white 
                hover:text-black 
                transition 
                duration-300 
                shadow-[0_0_15px_rgba(0,0,0,0.05)] 
                hover:shadow-[0_0_20px_rgba(0,0,0,0.1)]
                w-fit
                capitalize
              "
            >
              Explore All Topics
            </button>
          </div>
        </div>

        {/* Right column */}
        <div className="lg:w-[61.8%] flex items-center">
          {isLoading ? (
            <div className="flex justify-center items-center h-32 w-full">
              <Loader2 className="animate-spin text-black/50" size={32} />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-6 w-[80%] mx-auto">
              {topics.map((topic, index) => (
                <motion.div
                  key={topic.name}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: index * 0.15,
                    duration: 0.6,
                    ease: [0.23, 1, 0.32, 1]
                  }}
                  onMouseMove={handleMouseMove}
                  className={cn(
                    "aspect-square rounded-2xl p-6",
                    "flex flex-col items-center justify-center gap-3",
                    "relative overflow-hidden",
                    "border border-black/10",
                    "shadow-[0_0_30px_rgba(0,0,0,0.03)]",
                    "hover:border-black/20",
                    "transition-all duration-500 ease-out",
                    "group cursor-pointer",
                    "before:absolute before:inset-0",
                    "before:opacity-0 group-hover:before:opacity-100",
                    "before:transition-opacity before:duration-500",
                    "before:bg-[radial-gradient(circle_at_var(--mouse-x)_var(--mouse-y),rgba(0,0,0,0.03)_0%,transparent_50%)]",
                    "after:absolute after:inset-0",
                    "after:opacity-0 group-hover:after:opacity-100",
                    "after:transition-opacity after:duration-500",
                    "after:bg-[radial-gradient(circle_at_var(--mouse-x)_var(--mouse-y),rgba(0,0,0,0.02)_0%,transparent_25%)]"
                  )}
                  onClick={() => handleTopicClick(topic.name)}
                  style={{
                    background: `linear-gradient(145deg, 
                      rgba(0,0,0,${topic.count / 2000}) 0%, 
                      transparent 100%)`
                  }}
                >
                  <h3 className="text-xl font-light relative z-10 text-black/80
                    group-hover:text-black transition-colors duration-500 text-center">
                    {topic.name}
                  </h3>
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TopicsFeatureCard;
