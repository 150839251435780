"use client";

import { motion } from "framer-motion";
import React from "react";

/**
 * A minimalist black & white hero section with a transparent container and black text.
 * Keeps the original tagline: "Experience the next generation of video podcasting. We're live!"
 * 
 * Best practice: Pair with a global or parent background color of white (or light gray).
 * Uses the Montserrat font via Tailwind’s font-[Montserrat] utility (ensure you load Montserrat).
 */

interface LandingHeroProps {
  isDevComplete: boolean;
}

const LandingHero: React.FC<LandingHeroProps> = ({ isDevComplete }) => {
  return (
    <section className="w-full py-8 flex items-center justify-center mt-16 sm:mt-20">
      {/* Hero container - reduced padding and border radius */}
      <div className="relative w-[90%] max-w-[1200px] mx-auto bg-transparent p-8 sm:p-12 flex items-center justify-center
          border-[3px] border-black rounded-[40px] 
          [border-radius:40px_60px_30px_70px]
          transform hover:scale-[1.02] transition-transform duration-300
          shadow-[6px_6px_0px_0px_rgba(0,0,0,1)]
          hover:shadow-[10px_10px_0px_0px_rgba(0,0,0,1)]
          transition-all duration-300
          translate-y-[-4px] hover:translate-y-[-8px]">
        {/* Main heading - reduced text sizes */}
        <motion.h1
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="
            text-black font-[Montserrat] 
            text-5xl sm:text-6xl md:text-7xl lg:text-8xl
            tracking-tight 
            mb-4
            text-center
            max-w-full
            leading-[0.9]
          "
        >
          Melo TV
        </motion.h1>

        {/* Subheading (Original tagline if `isDevComplete`, otherwise dev message) */}
        {isDevComplete ? (
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-black text-base sm:text-lg md:text-xl font-light"
          >
          </motion.p>
        ) : (
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-black text-base sm:text-lg md:text-xl font-light"
          >
          </motion.p>
        )}
      </div>
    </section>
  );
};

export default LandingHero;