// MobileControls.tsx

import React, { memo } from 'react';
import Controls from './Controls';
import VolumeControl from './VolumeControl';
import PlaybackSpeedControl from './PlaybackSpeedControl';
import VideoSwitch from './VideoSwitch';
import TimeDisplay from './TimeDisplay';
import FullscreenButton from './FullscreenButton';

// #region [ Interfaces / Types ]

export interface MobileControlsProps {
  /** Indicates whether the video is currently playing or paused. */
  isPlaying: boolean;

  /** Handles toggling between play and pause states. */
  onPlayPause: () => void;

  /** Handler for rewinding the video (e.g., 10 seconds). */
  onRewind: () => void;

  /** Handler for fast-forwarding the video (e.g., 10 seconds). */
  onForward: () => void;

  /** Current volume level (0.0 to 1.0). */
  volume: number;

  /** Called when the volume slider changes. */
  onVolumeChange: (volume: number) => void;

  /** Current playback speed (e.g., 1.0 for normal speed). */
  speed: number;

  /** Called when the user selects a new playback speed. */
  onSpeedChange: (speed: number) => void;

  /** Whether we are viewing a full episode or just a clip. */
  isFullEpisode: boolean;

  /** Handler for switching between full-episode mode and clip mode. */
  onToggle: () => void;

  /** The current playback time in seconds. */
  currentTime: number;

  /** The total duration in seconds (full episode or clip). */
  duration: number;

  /** Handler for toggling fullscreen mode. */
  onFullscreen: () => void;
}

// #endregion

/**
 * A container of playback and volume controls optimized for mobile layouts.
 * It includes volume control, basic playback controls, speed adjustment,
 * and switching between clip and full-episode modes.
 */
const MobileControls: React.FC<MobileControlsProps> = memo(
  ({
    isPlaying,
    onPlayPause,
    onRewind,
    onForward,
    volume,
    onVolumeChange,
    speed,
    onSpeedChange,
    isFullEpisode,
    onToggle,
    currentTime,
    duration,
    onFullscreen,
  }) => {
    return (
      <div className="flex flex-col space-y-1">
        {/* Top row: volume, rewind/play/forward, speed controls */}
        <div className="flex justify-between items-center">
          <VolumeControl volume={volume} onVolumeChange={onVolumeChange} />

          <Controls
            isPlaying={isPlaying}
            onPlayPause={onPlayPause}
            onRewind={onRewind}
            onForward={onForward}
          />

          <PlaybackSpeedControl speed={speed} onSpeedChange={onSpeedChange} />
        </div>

        {/* Bottom row: toggle full episode / clip, time display, fullscreen toggle */}
        <div className="flex justify-between items-center text-xs">
          <VideoSwitch isFullEpisode={isFullEpisode} onToggle={onToggle} />

          <TimeDisplay currentTime={currentTime} duration={duration} />

          <FullscreenButton onClick={onFullscreen} />
        </div>
      </div>
    );
  }
);

MobileControls.displayName = 'MobileControls';
export default MobileControls;