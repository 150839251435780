// FullscreenButton.tsx

import React, { memo, useState, useEffect } from 'react';
import { Maximize, Minimize } from 'lucide-react';

// #region [ Interfaces / Types ]

/**
 * Describes the props required by the FullscreenButton component.
 */
export interface FullscreenButtonProps {
  /**
   * Callback invoked when the button is clicked to either
   * enter or exit fullscreen mode.
   */
  onClick: () => void;
}

// #endregion

/**
 * A button that toggles between fullscreen and normal screen modes.
 *
 * By wrapping in `React.memo`, we can avoid unnecessary re-renders
 * if parent components cause re-renders with unrelated props.
 */
const FullscreenButton: React.FC<FullscreenButtonProps> = memo(({ onClick }) => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  useEffect(() => {
    /**
     * Event listener callback that updates the `isFullscreen` state
     * whenever the browser's fullscreen change event is triggered.
     */
    const handleFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement));
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <button
      type="button"
      className="p-2 rounded-full hover:bg-white/10 transition-colors"
      onClick={onClick}
      aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
    >
      {isFullscreen ? (
        <Minimize className="w-5 h-5 text-white" />
      ) : (
        <Maximize className="w-5 h-5 text-white" />
      )}
    </button>
  );
});

FullscreenButton.displayName = 'FullscreenButton';
export default FullscreenButton;