// VideoPlayerManager.tsx

import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import Hls from 'hls.js';
import Controls from './Controls';
import VolumeControl from './VolumeControl';
import PlaybackSpeedControl from './PlaybackSpeedControl';
import FullscreenButton from './FullscreenButton';
import VideoSwitch from './VideoSwitch';
import TimeDisplay from './TimeDisplay';
import ProgressBar from './ProgressBar';
import { useMediaQuery } from 'react-responsive';
import MobileControls from './MobileControls';
import HotkeysHandler from './HotkeysHandler';

// #region [ Interfaces / Types ]

export interface VideoPlayerManagerProps {
  url: string;
  start: number;
  end: number;
  isFullEpisode: boolean;
  onToggleFullEpisode: () => void;
  clipDuration: number;
  podcastName: string;
  episodeName: string;
}

// #endregion

/**
 * This component manages the video element, including play/pause, volume,
 * speed, seeking, and the difference between clip and full-episode viewing.
 */
const VideoPlayerManager: React.FC<VideoPlayerManagerProps> = ({
  url,
  start,
  end,
  isFullEpisode,
  onToggleFullEpisode,
  clipDuration,
  podcastName,
  episodeName,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(1);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [fullDuration, setFullDuration] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isControlsVisible, setIsControlsVisible] = useState<boolean>(true);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hlsRef = useRef<Hls | null>(null);
  const controlsTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // #region [ HLS Initialization ]

  /**
   * Sets up Hls.js (or uses native HLS if supported) to load the video source.
   */
  const initHls = useCallback(() => {
    if (Hls.isSupported() && videoRef.current) {
      hlsRef.current = new Hls();
      hlsRef.current.loadSource(url);
      hlsRef.current.attachMedia(videoRef.current);
      hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
        setIsLoaded(true);
        if (videoRef.current) {
          videoRef.current.currentTime = start;
        }
      });
    } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
      // Some iOS Safari versions support HLS natively
      videoRef.current.src = url;
      videoRef.current.addEventListener('loadedmetadata', () => {
        setIsLoaded(true);
        if (videoRef.current) {
          videoRef.current.currentTime = start;
        }
      });
    }
  }, [url, start]);

  useEffect(() => {
    initHls();
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [initHls]);

  // #endregion

  // #region [ Playback Controls ]

  const handlePlayPause = useCallback(() => {
    setIsPlaying((prev) => !prev);
  }, []);

  const handleRewind = useCallback(() => {
    if (videoRef.current) {
      const newTime = Math.max(
        videoRef.current.currentTime - 10,
        isFullEpisode ? 0 : start
      );
      videoRef.current.currentTime = newTime;
    }
  }, [isFullEpisode, start]);

  const handleForward = useCallback(() => {
    if (videoRef.current) {
      const limit = isFullEpisode ? fullDuration : end;
      const newTime = Math.min(videoRef.current.currentTime + 10, limit);
      videoRef.current.currentTime = newTime;
    }
  }, [isFullEpisode, end, fullDuration]);

  const handleVolumeChange = useCallback(
    (newVolume: number) => {
      setVolume(newVolume);
      if (videoRef.current) {
        videoRef.current.volume = newVolume;
      }
    },
    []
  );

  const handleSpeedChange = useCallback(
    (newSpeed: number) => {
      setPlaybackSpeed(newSpeed);
      if (videoRef.current) {
        videoRef.current.playbackRate = newSpeed;
      }
    },
    []
  );

  const handleSeek = useCallback(
    (seekTime: number) => {
      if (!videoRef.current) return;
      const actualSeekTime = isFullEpisode
        ? seekTime
        : Math.min(Math.max(seekTime + start, start), end);

      videoRef.current.currentTime = actualSeekTime;
      setCurrentTime(isFullEpisode ? actualSeekTime : seekTime);
    },
    [isFullEpisode, start, end]
  );

  // #endregion

  // #region [ Time / Duration ]

  const handleTimeUpdate = useCallback(() => {
    if (!videoRef.current) return;
    const videoCurrentTime = videoRef.current.currentTime;
    setCurrentTime(isFullEpisode ? videoCurrentTime : videoCurrentTime - start);

    // Auto-reset for clip mode
    if (!isFullEpisode && videoCurrentTime >= end) {
      videoRef.current.pause();
      videoRef.current.currentTime = start;
      setIsPlaying(false);
    }
  }, [isFullEpisode, start, end]);

  const handleDurationChange = useCallback(() => {
    if (videoRef.current) {
      setFullDuration(videoRef.current.duration);
    }
  }, []);

  const handleEnded = useCallback(() => {
    setIsPlaying(false);
    if (videoRef.current) {
      videoRef.current.currentTime = isFullEpisode ? 0 : start;
    }
  }, [isFullEpisode, start]);

  // #endregion

  // #region [ Fullscreen Toggle ]

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement && containerRef.current) {
      containerRef.current.requestFullscreen().catch((err) => {
        console.error('Error attempting to enable fullscreen', err);
      });
    } else if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error('Error attempting to exit fullscreen', err);
      });
    }
  }, []);

  // #endregion

  // #region [ Handle Play/Pause states ]

  useEffect(() => {
    if (!videoRef.current) return;
    if (isPlaying) {
      videoRef.current.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  // #endregion

  // #region [ Initialize start time when loaded ]

  useEffect(() => {
    if (videoRef.current && isLoaded) {
      // Always start from 'start' timestamp (even for full episodes to keep consistency)
      videoRef.current.currentTime = start;
      setCurrentTime(isFullEpisode ? start : 0);
    }
  }, [isFullEpisode, start, isLoaded]);

  // #endregion

  // #region [ Controls Visibility ]

  const showControls = useCallback(() => {
    setIsControlsVisible(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    controlsTimeoutRef.current = setTimeout(() => {
      setIsControlsVisible(false);
    }, 3000); // Hide controls after 3 seconds
  }, []);

  const handleMouseMove = useCallback(() => {
    showControls();
  }, [showControls]);

  const handleMouseLeave = useCallback(() => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    setIsControlsVisible(false);
  }, []);

  useEffect(() => {
    return () => {
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
    };
  }, []);

  // #endregion

  // #region [ Video Click -> Toggle Play/Pause ]

  const handleVideoClick = useCallback(() => {
    handlePlayPause();
  }, [handlePlayPause]);

  // #endregion

  // #region [ Mobile / Desktop Check + Hotkey Volume Handlers ]

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  useEffect(() => {
    setIsMobileView(isMobile);
  }, [isMobile]);

  const handleVolumeUp = useCallback(() => {
    handleVolumeChange(Math.min(volume + 0.1, 1));
  }, [volume, handleVolumeChange]);

  const handleVolumeDown = useCallback(() => {
    handleVolumeChange(Math.max(volume - 0.1, 0));
  }, [volume, handleVolumeChange]);

  // #endregion

  // #region [ Focus Container for Keyboard Events ]

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer) {
      const handleMouseEnter = () => {
        currentContainer.focus();
      };
      currentContainer.addEventListener('mouseenter', handleMouseEnter);
      return () => {
        currentContainer.removeEventListener('mouseenter', handleMouseEnter);
      };
    }
  }, []);

  // #endregion

  return (
    <div
      ref={containerRef}
      className="relative w-full h-0 pb-[56.25%]"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      tabIndex={0} // Make container focusable for keyboard events
    >
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full"
        onTimeUpdate={handleTimeUpdate}
        onDurationChange={handleDurationChange}
        onEnded={handleEnded}
        onClick={handleVideoClick}
      />

      {/* Hotkeys */}
      <HotkeysHandler
        onPlayPause={handlePlayPause}
        onRewind={handleRewind}
        onForward={handleForward}
        onVolumeUp={handleVolumeUp}
        onVolumeDown={handleVolumeDown}
        onToggleFullscreen={toggleFullscreen}
        containerRef={containerRef}
      />

      {/* Controls Overlay */}
      <div
        className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-30 backdrop-blur-md p-2 transition-opacity duration-300 ${
          isControlsVisible ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <ProgressBar
          currentTime={currentTime}
          duration={isFullEpisode ? fullDuration : clipDuration}
          onSeek={handleSeek}
        />

        {isMobileView ? (
          <MobileControls
            isPlaying={isPlaying}
            onPlayPause={handlePlayPause}
            onRewind={handleRewind}
            onForward={handleForward}
            volume={volume}
            onVolumeChange={handleVolumeChange}
            speed={playbackSpeed}
            onSpeedChange={handleSpeedChange}
            isFullEpisode={isFullEpisode}
            onToggle={onToggleFullEpisode}
            currentTime={currentTime}
            duration={isFullEpisode ? fullDuration : clipDuration}
            onFullscreen={toggleFullscreen}
          />
        ) : (
          <div className="flex items-center justify-between mt-1 relative">
            <VolumeControl volume={volume} onVolumeChange={handleVolumeChange} />

            <div className="absolute left-1/2 transform -translate-x-1/2">
              <Controls
                isPlaying={isPlaying}
                onPlayPause={handlePlayPause}
                onRewind={handleRewind}
                onForward={handleForward}
              />
            </div>

            <div className="flex items-center space-x-2">
              <PlaybackSpeedControl
                speed={playbackSpeed}
                onSpeedChange={handleSpeedChange}
              />

              <VideoSwitch
                isFullEpisode={isFullEpisode}
                onToggle={onToggleFullEpisode}
              />

              <TimeDisplay
                currentTime={currentTime}
                duration={isFullEpisode ? fullDuration : clipDuration}
              />

              <FullscreenButton onClick={toggleFullscreen} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayerManager;