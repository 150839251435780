// PlaybackSpeedControl.tsx

import React, { memo, useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

// #region [ Interfaces / Types ]

/**
 * Props for the PlaybackSpeedControl component.
 */
export interface PlaybackSpeedControlProps {
  /**
   * The current playback speed (e.g., 1.0 for normal speed).
   */
  speed: number;

  /**
   * Handler called when the user selects a new speed (e.g., 1.5 or 2.0).
   */
  onSpeedChange: (speed: number) => void;
}

// #endregion

/**
 * A small menu component for selecting playback speeds, ranging from 0.5x to 2.0x
 * in increments of 0.1. Hover and click animations are handled by Framer Motion.
 */
const PlaybackSpeedControl: React.FC<PlaybackSpeedControlProps> = memo(
  ({ speed, onSpeedChange }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);

    // Generate an array of speeds from 0.5 to 2.0 in increments of 0.1
    const speeds = Array.from({ length: 16 }, (_, i) =>
      (0.5 + i * 0.1).toFixed(1)
    );

    /**
     * Closes the speed menu if the user clicks outside of it.
     */
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    /**
     * Updates the playback speed and closes the menu.
     */
    const handleSpeedClick = (newSpeed: string) => {
      const numericSpeed = parseFloat(newSpeed);
      onSpeedChange(numericSpeed);
      setIsOpen(false);
    };

    return (
      <div className="relative" ref={menuRef}>
        <motion.button
          className="
            relative px-4 py-1.5 text-xs font-medium
            transition-all duration-300 ease-in-out
            bg-white/5 hover:bg-white/10
            text-white/90
            focus:outline-none focus:ring-2 focus:ring-white/30
            group
            font-quicksand lowercase
          "
          onClick={() => setIsOpen((prev) => !prev)}
          aria-haspopup="true"
          aria-expanded={isOpen}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <span className="relative z-10">{speed.toFixed(1)}x</span>
          <span
            className="
              absolute inset-0
              bg-gradient-to-r from-gray-400/5 via-gray-500/5 to-gray-900/5
              group-hover:from-gray-400/10 group-hover:via-gray-500/10 group-hover:to-gray-900/10
              transition-opacity duration-300 ease-in-out
            "
          />
        </motion.button>

        {isOpen && (
          <div
            className="
              absolute bottom-full left-0 mb-2
              bg-black/50
              border border-white/20
              rounded-none
              shadow-lg
              max-h-60
              overflow-y-auto
              z-50
            "
          >
            {speeds.map((s) => (
              <motion.button
                key={s}
                className={`
                  block w-full px-4 py-2 text-left text-xs
                  ${
                    parseFloat(s) === speed
                      ? 'text-blue-400'
                      : 'text-white/90'
                  }
                  hover:bg-white/10 transition-colors
                  font-quicksand lowercase
                `}
                onClick={() => handleSpeedClick(s)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {s}x
              </motion.button>
            ))}
          </div>
        )}
      </div>
    );
  }
);

PlaybackSpeedControl.displayName = 'PlaybackSpeedControl';
export default PlaybackSpeedControl;